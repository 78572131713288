.dark-mode {
  background-color: #292929;
  color: #fff;
}

.dark-mode-card {
  background-color: #181818;
  color: #fff;
}

  
body {
  background-color: #222;
  color: #fff;
}


$grid-columns:      12;
$grid-gutter-width: 30px;

$grid-breakpoints: (
  // Extra small screen / phone
  xs: 0,
  // Small screen / phone
  sm: 576px,
  // Medium screen / tablet
  md: 768px,
  // Large screen / desktop
  lg: 992px,
  // Extra large screen / wide desktop
  xl: 1200px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px
);

.header {
  height: 7rem;
  display    : block;
  top        : 0;
  padding-top: 0;
}


// div {
//   display: grid;
// }

.row .div-dolar{ 
  width: 320px;
  height:260px;
  border-radius:10px;
  box-shadow:2px 4px 4px rgb(0 0 0);
  display: grid;
  justify-content: center;
  border:  1px solid #ffffff;
  margin: 10px;
    .div-dolar {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    background-color: #222;
    width: 300px;
    max-height: 240px;
    min-height: 240px;
  }

}

.container {
  width: 100%;
  background-color: #333;
  color: #222;
  margin-left     : auto;
  margin-right    : auto;
  display: grid;
  justify-content: center;
}


@media screen and (min-width: 700px) {

  .container {
    width: 100%;
    margin-left     : auto;
    margin-right    : auto;
    display: grid;
    grid-template-columns : repeat(2, 1fr);
    justify-content: center;
  }

}

@media screen and (min-width: 1020px) {

  .container {
    width: 100%;
    margin-left     : auto;
    margin-right    : auto;
    display: grid;
    grid-template-columns : repeat(3, 1fr);
    justify-content: center;
  }


}


.main-footer {
  display         : flex;
  justify-content : center;
  align-items     : center;
  margin-top      : auto;
  width           : 100%;
  height          : 10rem;
  font-size       : var(--small-font-size);
  bottom          : 0;
  padding-top: 2rem;
}

.main-footer p {
  margin-bottom : 0;
}

.tituloDolarBlackRecuadro {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  height: 2rem;
  padding-bottom: 0px;
}

.p-valorDolar {
      color: #ffffff;
      font-size: 20px;
      display: flex;
      margin: 0rem 2rem 0rem 2rem;
    }
    
    .p-lastUpdate {
      color: #ffffff;
      font-size: 10px;
      display: flex;
      margin-left: 2rem;
      margin-right: 2rem;
      text-align:justify;
      padding-bottom: 0rem;
    }